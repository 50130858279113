import React, { useMemo, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { ReactComponent as ClockIcon } from '../assets/svgs/clock.svg';
import { ReactComponent as DiplomIcon } from '../assets/svgs/diplom.svg';
import { ReactComponent as ChatIcon } from '../assets/svgs/chat.svg';
import { ReactComponent as MeditationIcon } from '../assets/svgs/meditation.svg';
import { ReactComponent as InfluencerIcon } from '../assets/svgs/influencer.svg';

import { STYLES } from '../enums';

import { BrowserContext } from '../contexts/browser';
import useIsLoggedIn from '../hooks/useIsLoggedIn';
import Button from '../components/clickables/button';
import Texticon from '../components/texticon/texticon';
import Stack from '../components/systems/stack';
import VideoPlayer from '../components/player/player';
import SEO from '../components/layout/seo';

const LandingView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const WelcomeText = styled.div`
  max-width: 500px;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    max-width: 90vw;
    margin-top: 50px;
  }
`;

const VideoContainer = styled.div`
  margin-top: 10px;
  width: 35vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 90vw;
  }
`;

const TextIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  margin: 15vh 0 0 0;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Concept = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: left;
margin: 15vh 0 0 0;
@media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

div {
  width: 30vw;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
  }
}

svg {
  width: 30vw;
  max-width: 500px;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
}   
`;

const Sub = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: left;
margin: 15vh 0 0 0;
@media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

svg {
  width: 30vw;
  max-width: 500px;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
}     

p {
  max-width: 300px;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    max-width: 90vw;
  }
}
`;

const textIcons = [
  {
    key: 'JEDERZEIT',
    title: 'JEDERZEIT UND ÜBERALL',
    description:
      'und das in Ihrem Tempo und von Ihrem persönlichen Wohlfühl-Ort aus.',
    svg: <ClockIcon />,
  },
  {
    key: 'PSYCHOLOGIN',
    title: 'QUALIFIZIERTE PSYCHOLOGIN',
    description:
      'mit viel Wissen durch jahrzehntelange Aus- und Weiterbildungen.',
    svg: <DiplomIcon />,
  },
  {
    key: 'PERSÖNLICH',
    title: 'MIT PERSÖNLICHER NOTE',
    description: 'durch vielseitige Lebenserfahrungen.',
    svg: <ChatIcon />,
  },
];

const LandgingPage = () => {
  const { isPad } = useContext(BrowserContext);
  const isLoggedIn = useIsLoggedIn();

  const button = useMemo(
    () =>
      isLoggedIn ? (
        <Button
          label="Jetzt Weitermachen"
          onClick={() => navigate('/courses')}
          primary
        />
      ) : (
        <Button
          label="Jetzt Anmelden"
          onClick={() => navigate('/signup')}
          primary
        />
      ),
    [isLoggedIn]
  );

  console.log('isPad', isPad);

  return (
    <>
      <SEO title="" />
      <LandingView>
        <WelcomeText>
          <Stack gap="40px" collapseX textCentered>
            <h1>PSYCHOLOGIE FÜR SIE</h1>
            <p>
              … oder was Sie schon immer über Psychologie, Psychotherapie,
              mentale Prozesse oder das Mensch-sein wissen wollten.
            </p>
            {button}
          </Stack>
        </WelcomeText>
        <VideoContainer>
          <VideoPlayer
            index={0}
            link="https://vimeo.com/450874937"
            latestProgress={0}
            widthPercentage={isPad ? 0.9 : 0.35}
          />
        </VideoContainer>
      </LandingView>
      <TextIcons>
        {textIcons.map(ti => (
          <Texticon
            key={ti.key}
            title={ti.title}
            description={ti.description}
            SVG={ti.svg}
          />
        ))}
      </TextIcons>
      <Concept>
        <MeditationIcon />
        <Stack
          gap="30px"
          centered={false}
          margin={isPad ? '50px 0 0 0' : '0 0 0 15vw'}
          collapseX
        >
          <Stack centered={isPad}>
            <h2>ONLINE SEMINARE</h2>
          </Stack>
          <p>
            Ich biete Ihnen videobasierte Seminare zum Thema Mensch-sein an und
            dies stützend auf dem Wissen um die moderne Psychologie, Kenntnisse
            aus Coaching/Psychotherapie und Erklärungen zu mentalen Prozessen.
          </p>
          <p>
            Wie funktioniert das hier? Sie sehen sich die kostenlosen
            Einführungen und Previews an und entscheiden, ob das jeweilige Video
            etwas für Sie ist. Danach klicken Sie auf den Bestellknopf und das
            Video gehört Ihnen und ist für Sie abspielbar. Einfacher geht es
            nicht.
          </p>
        </Stack>
      </Concept>
      {false && (
        <Sub>
          <Stack
            gap="30px"
            collapseX
            margin={isPad ? '50px 0 0 0' : '0 15vw 0 0'}
          >
            <Stack centered={isPad}>
              <h2>SEMINARE</h2>
            </Stack>
            <ul>
              <li>Entspannungstechniken</li>
              <li>Stressbewältigung</li>
              <li>Psychische Gesundheit/Krankheit</li>
              <li>Spezifische Themen</li>
            </ul>
          </Stack>
          <InfluencerIcon />
        </Sub>
      )}
      <Stack gap="30px" margin="14vh 0 5vh 0" textCentered>
        <h2>ZEIT FÜR SIE</h2>
        <p>
          um SIE selber in den Mittelpunkt zu stellen und Neues zu lernen. Dies
          kann über videobasierte und pdf Anleitungen geschehen.
        </p>
        <Button
          label="Zu den Seminaren"
          onClick={() => navigate('/courses')}
          primary
        />
      </Stack>
    </>
  );
};

export default LandgingPage;
