import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { STYLES } from '../../enums';
import Stack from '../systems/stack';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2vh 2vw;
  width: 30vw;

  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 80vw;
  }

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
  }

  svg {
    align-self: center;
    width: 7vh;
    height: 7vh;
    margin: 20px;
    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      width: 7vh;
      height: 7vh;
    }
  }

  h2 {
    align-self: center;
  }

  p {
    align-self: center;
  }
`;

const Texticon = ({ title, description, SVG }) => (
  <Container>
    <Stack gap="20px" textCentered>
      {SVG}
      <h2>{title}</h2>
      <p>{description}</p>
    </Stack>
  </Container>
);

Texticon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  SVG: PropTypes.node.isRequired,
};

export default Texticon;
